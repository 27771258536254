import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";

import googleButtonImg from "../img/google-button-x1.png";

function GoogleLoginButton() {
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    localStorage.setItem("applicationClientId", queryParameters.get("client_id"));
    localStorage.setItem("applicationRedirectUri", queryParameters.get("redirect_uri"));
  }, []);
  const login = useGoogleLogin({
    scope:
      "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/fitness.activity.read",
    onSuccess: (res) => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/web_google`,
          qs.stringify({
            authCode: res.code,
            applicationClientId: localStorage.getItem("applicationClientId"),
            applicationRedirectUri: localStorage.getItem("applicationRedirectUri"),
          })
        )
        .then((success) => {
          window.location.href = `${success.data.redirect_uri}?code=${encodeURIComponent(
            success.data.code
          )}&expires_at=${success.data.expires_at}`;
        });
    },
    onError: (res, err) => {
      console.log("Login failed: res:", res);
      console.error(err);
    },
    flow: "auth-code",
  });

  return (
    <div>
      <button
        className="oauth-container btn darken-4 white black-text"
        style={{ textTransform: "none", width: 195, marginBottom: 10 }}
        onClick={login}
      >
        <div className="left">
          <img
            width={20}
            style={{ marginTop: 7, marginRight: 8 }}
            alt="Google sign-in"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
          />
        </div>
        Login with Google
      </button>
    </div>
  );
}

export default GoogleLoginButton;
