// import logo from "./logo.svg";

import "./App.css";

import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./components/googleLogin";
import AppleLoginButton from "./components/appleLogin";
import AppstoreDownloadSVG from "./svg/AppstoreDownload";
import GooglePlayImg from "./img/google-play.png";
import citizenLogo from "./img/citizen-logo.png";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function App() {
  return (
    <>
      {/* <img src={logo} width="100" alt="logo" /> */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <div className="App">
          <br />
          <br />
          <img src={citizenLogo} width={200} />
          <br />
          <br />
          <br />
          <GoogleLoginButton />
          <AppleLoginButton />
          <br />
          <br />
          <br />
          {getMobileOperatingSystem() === "iOS" ? (
            <a href="https://apps.apple.com/us/app/youq/id6443921069">
              <AppstoreDownloadSVG />
            </a>
          ) : null}
          {getMobileOperatingSystem() === "Android" ? (
            <a href="https://play.google.com/store/apps/details?id=com.czsmart.youq&pli=1">
              <img src={GooglePlayImg} alt="Download on google play!" />
            </a>
          ) : null}
        </div>
      </GoogleOAuthProvider>
    </>
  );
}
export default App;
